import React, { useState, useEffect } from "react";
import Banner from "./components/Banner";
import Campusvideo from "./components/Campusvideo";
import HomepageCarousel from "./components/HomepageCarousel";
import Newssection from "./components/Newssection";
import Recruiter from "./components/Recruiter";
import Courssection from "./components/Courssection";

export default function Homepage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentDialogIndex, setCurrentDialogIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dialogs = [
    {
      title: "Apply for Ph.D. Programs",
      content: (
        <img
          src="images/phd_admission.jpg"
          alt=""
          style={{
            objectFit: "fit",
            maxWidth: "100%",
            marginBottom: "20px",
            marginRight: "20px",
          }}
        />
      ),
    },
    {
      title: "Apply for Associate/Assistant Professor",
      content: (
        <img
          src="images/poster_faculty.jpeg"
          alt=""
          style={{
            objectFit: "fit",
            maxWidth: "100%",
            marginBottom: "20px",
            marginRight: "20px",
          }}
        />
      ),
    },
    // Add more dialogs as needed
  ];

  const handleCloseDialog = () => {
    if (currentDialogIndex < dialogs.length - 1) {
      setCurrentDialogIndex(currentDialogIndex + 1);
    } else {
      setCurrentDialogIndex(null);
    }
  };

  return (
    <>
      <HomepageCarousel />
      <Courssection />
      {/* <Banner /> */}
      <Campusvideo />
      {/* <Recruiter /> */}
      {currentDialogIndex !== null && (
        <div
          className="modal"
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="modal-content"
            style={{
              maxWidth: "800px",
              margin: "10px",
              maxHeight: "90vh",
            }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                {dialogs[currentDialogIndex].title}
              </h3>
              <button onClick={handleCloseDialog} className="close">
                &times;
              </button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <center>{dialogs[currentDialogIndex].content}</center>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
